var forgotPwdLib = function () {
    var private = {
        token: '',
        modal: $('#modal-placeholder'),
        errorText: $('#forgotPwdErrorMsg'),
        isLinkExpired:false,
        clear: function() {
            $('#modal-placeholder').html('');
            $('#forgotPwdErrorMsg').text('');
        },
        setupOnClickEvent: function() {
            var self = private;
            $('.forgot-password-link').on('click',
                function(e) {
                    $.ajax({
                        type: 'get',
                        dataType: "html",
                        url: "ForgotPassword",
                        beforeSend: function () {
                            DmUiLibrary.ShowOverlaySpinner();
                        },
                        success: function (data) {
                            //self.modal.html($.parseHTML(data));
                            $('#modal-placeholder').html($.parseHTML(data));
                            $('#resetDiag').hide();
                            $('#email').focus();

                            $('#email').keypress(function (e) {
                                    if (e.keyCode == 13) {
                                        $('#modalSubmit').click();
                                        return false;
                                    }
                                });

                            $('#modalCancel').on('click',
                                function (e) {
                                    e.preventDefault();
                                    self.clear();
                                });

                            $('#forgotPassword').on('submit',
                                function (e) {
                                    e.preventDefault();
                                    var frm = $(this);
                                    if (frm.valid()) {
                                        self.forgotPassword(frm);
                                    }
                                });

                            $('#btnBackSignIn').on('click',
                                function(e) {
                                    e.preventDefault();
                                    self.clear();
                                    $('#UserEmail').focus();
                                });

                            $.validator.unobtrusive.parse("#forgotPassword");

                            if (private.isLinkExpired) {
                                $("#hdrForgotPassword").text("Reset Password");
                                $('#forgotPassword').submit();
                                $("#email-error").text("Previous reset password link expired. Please resubmit.");
                                private.isLinkExpired = false;
                            }
                        },
                        error: function(xhr, status, err) {
                            console.error(status, err.toString());
                        },
                        complete: function () {
                            private.setupTabKeyEvent();
                            DmUiLibrary.HideOverlaySpinner();
                        }
                    });
                });

            if (window.location.search.indexOf("link=false") === 1) {
                private.isLinkExpired = true;
                $('.forgot-password-link').click();
            }
        },
        setupTabKeyEvent: function () {
            var firstInput = $('#modal-placeholder').find('*').filter(':input:enabled:visible:first');
            var lastInput = $('#modal-placeholder').find('*').filter(':input:enabled:visible:last');
            $('#modal-placeholder').on("keydown",
                '#' + lastInput[0].id,
                function (e) {
                    if (e.keyCode === 9 && !e.shiftKey) {
                        e.preventDefault();
                        firstInput.focus();
                    }
                });

            $('#modal-placeholder').on("keydown",
                '#' + firstInput[0].id,
                function (e) {
                    if (e.keyCode === 9 && e.shiftKey) {
                        e.preventDefault();
                        lastInput.focus();
                    }
                });
        },
        forgotPassword: function(frm) {
            var data = frm.serialize();
            $.ajax({
                type: frm.attr('method'),
                url: frm.attr('action'),
                data: data,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
                beforeSend: function () {
                    DmUiLibrary.ShowOverlaySpinner();
                },
                success: function (jsonData) {
                    var response = $.parseJSON(jsonData);
                    if (response.success === false) {
                        $('#forgotPwdErrorMsg').html($.parseHTML(response.errors));
                        $('#email').val('');
                        $('#email').focus();

                        return;
                    }
                    $('#forgotDiag').hide();
                    $('#resetDiag').show();
                    $('#btnBackSignIn').focus();
                },
                failure: function (xhr, status, err) {
                    console.error(status, err.toString());
                },
                complete: function () {
                    DmUiLibrary.HideOverlaySpinner();
                }
            });

        }
    };

    return {
        init: function () {
            private.setupOnClickEvent();
        },
        errors: private.errors
    };

}();