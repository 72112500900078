var loginLib = function () {

    var private = {
        password: $("#password-content"),
        sessionCode: $('#session-content'),
        proctorTab: $('#proctorLoginTab'),
        accessTab: $('#accessLoginTab'),
        proctor: $('#IsProctor'),

        setAdminView: function () {
            private.proctorTab.prop('disabled', false);
            private.accessTab.prop('disabled', true);

            private.proctor.val(false);
            private.sessionCode.removeClass('tab-content-section-large');
            private.sessionCode.addClass('tab-content-section');
            private.sessionCode.hide();
            private.password.removeClass('tab-content-section');
            private.password.addClass('tab-content-section-large');
    },

        setProctorview: function () {
            private.accessTab.prop('disabled', false);
            private.proctorTab.prop('disabled', true);
            private.proctor.val(true);
            private.sessionCode.removeClass('tab-content-section');
            private.sessionCode.addClass('tab-content-section-large');
            private.sessionCode.show();
            private.password.removeClass('tab-content-section-large');
            private.password.addClass('tab-content-section');
            private.sessionCode.show();
                },

        setupOnClickEvent: function () {
            var self = this;
            $('#accessLoginTab').on('click',
                function (e) {
                    e.preventDefault();
                    self.setView(false);
                });

            $('#proctorLoginTab').on('click',
                function (e) {
                    self.setView(true);
                });

            $('button[type=submit]').on('click',
                function (e) {
                    var isValid = $(e.target).parents('form').valid();
                    if (!isValid) {
                        e.preventDefault(); //prevent the default action
                    } else {
                        DmUiLibrary.ShowOverlaySpinner();
                    }
                });
        },

        setView: function (isProctor) {
            if (isProctor === true) {
                private.setProctorview();
                return;
            }
            private.setAdminView();
        }
    };

    return {
        init: function () {
            var isProctor = private.proctor.val();
            private.setView(isProctor === 'true');

            private.setupOnClickEvent();
        }
    };

}();