var touLib = function () {
    var private = {
        modal: $('#modal-placeholder'),
        returnUrl: '',
        getForm: function () {
            return $('#frmTou');
        },
        showTou: function (display) {
            var self = this;

            if (display === true) {
                $.ajax({
                    type: 'get',
                    dataType: 'html',
                    data: { returnUrl: self.returnUrl },
                    url: 'Tou',
                    beforeSend: function () {
                        DmUiLibrary.ShowOverlaySpinner();
                    },
                    success: function (data) {
                        $('#modal-placeholder').html($.parseHTML(data));
                        self.setupOnClickEvent(self);
                    },
                    error: function (xhr, status, err) {
                        console.error(status, err.toString());
                    },
                    complete: function () {
                        DmUiLibrary.HideOverlaySpinner();
                    }
                });
            }
        },

        accept: function () {
            var self = this;
            var frm = self.getForm();
            $.ajax({
                type: frm.attr('method'),
                url: frm.attr('action'),
                data: frm.serialize(),
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
                beforeSend: function () {
                    DmUiLibrary.ShowOverlaySpinner();
                },
                success: function (response) {
                    var data = $.parseJSON(response);
                    private.clear();
                    location.href = data.returnUrl; //location.origin + '/' + data.returnUrl;
                },
                error: function (xhr, status, err) {
                    console.error(status, err.toString());
                },
                complete: function () {
                    DmUiLibrary.HideOverlaySpinner();
                }
            });
        },

        decline: function () {
            var self = this;
            var frm = self.getForm();
            $.ajax({
                type: 'post',
                url: 'tou/decline',
                data: frm.serialize(),
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
                beforeSend: function () {
                    DmUiLibrary.ShowOverlaySpinner();
                },
                success: function (data) {
                    private.clear();
                    //location.reload();
                },
                error: function (xhr, status, err) {
                    console.error(status, err.toString());
                },
                complete: function () {
                    DmUiLibrary.HideOverlaySpinner();
                    self.clear();
                }
            });
        },

        clear: function() {
            $('#modal-placeholder').html('');
        },

        setupOnClickEvent: function (self) {
            $('#btnAccept').on('click',
                function (e) {
                    e.preventDefault();
                    self.accept();
                });

            $('#btnDecline').on('click',
                function (e) {
                    e.preventDefault();
                    self.decline();
                });
        }
    };

    return {
        init: function (displayTou, returnUrl) {
            private.returnUrl = returnUrl;
            private.showTou(displayTou.toLowerCase() === 'true');
        }
    };
}();