var reportingUserLib = function () {
    var private = {
        token: '',
        modal: $('#modal-placeholder'),
        errorText: $('#reportingUserErrorMsg'),
        clear: function () {
            $('#modal-placeholder').html('');
            $('#reportingUserErrorMsg').text('');
        },
        setupOnClickEvent: function () {
            var self = private;
            $('.reporting-user-link').on('click',
                function (e) {
                    $.ajax({
                        type: 'get',
                        dataType: "html",
                        url: "ReportingUser",
                        beforeSend: function () {
                            DmUiLibrary.ShowOverlaySpinner();
                        },
                        success: function (data) {
                            //self.modal.html($.parseHTML(data));
                            $('#modal-placeholder').html($.parseHTML(data));
                            $('#resetDiag').hide();
                            $('#firstName').focus();
                            $('#reportUserDialog').on('keypress',
                                function (e) {
                                    if (e.keyCode == 13) {
                                        $('#modalSubmit').click();
                                        return false;
                                    }
                                }
                            );

                            $('#modalCancel').on('click',
                                function (e) {
                                    e.preventDefault();
                                    self.clear();
                                });

                            $('#reportingUser').on('submit',
                                function (e) {
                                    e.preventDefault();
                                    var frm = $(this);
                                    if (frm.valid()) {
                                        self.ReportingUser(frm);
                                    }
                                });

                            $('#btnBackSignIn').on('click',
                                function (e) {
                                    e.preventDefault();
                                    self.clear();
                                });

                            $.validator.unobtrusive.parse("#reportingUser");
                        },
                        error: function (xhr, status, err) {
                            console.error(status, err.toString());
                        },
                        complete: function () {
                            private.setupTabKeyEvent();
                            DmUiLibrary.HideOverlaySpinner();
                        }
                    });
                });
        },
        setupTabKeyEvent: function () {
            var firstInput = $('#modal-placeholder').find('*').filter(':input:enabled:visible:first');
            var lastInput = $('#modal-placeholder').find('*').filter(':input:enabled:visible:last');
            $('#modal-placeholder').on("keydown",
                '#' + lastInput[0].id,
                function (e) {
                    if (e.keyCode === 9 && !e.shiftKey) {
                        e.preventDefault();
                        firstInput.focus();
                    }
                });

            $('#modal-placeholder').on("keydown",
                '#' + firstInput[0].id,
                function (e) {
                    if (e.keyCode === 9 && e.shiftKey) {
                        e.preventDefault();
                        lastInput.focus();
                    }
                });
        },

        ReportingUser: function (frm) {
            var data = frm.serialize();
            $.ajax({
                type: frm.attr('method'),
                url: frm.attr('action'),
                data: data,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
                beforeSend: function () {
                    DmUiLibrary.ShowOverlaySpinner();
                },
                success: function (jsonData) {
                    var response = $.parseJSON(jsonData);
                    if (response.success === false) {
                        var message = response.errors;
                        if (response.errors == 'Expired') {
                            message += 'You entered a key that has expired.';

                            message += ' Contact your school system’s DataManager Account Holder or Administrator for a new key.';


                        }

                        if (response.errors == 'Invalid Key') {
                            message += 'You entered a key that is not valid.';

                        }
                        if (response.errors == 'User Key Exists') {
                            if (data.IsActive.toLowerCase() == "true") {
                                message += 'You already have access to reports associated with this key. ';
                            } else {
                                message += 'You do not have permission to access the location associated with this key.';
                            }
                        }
                        if (response.errors == 'Invalid Location') {
                            message += 'You entered a key that is not valid (The Contract is not found for specified key).';

                        }

                        ////message += '<ul style="margin-left: 1.4em;">';
                        ////message += '<li>To add a reporting key, enter the key and click Add a key</li>';
                        ////message += '<li>To select reports, click DataManager Reports</li>';
                        ////message += '<li>To exit without adding reports, click Exit</li>';
                        ////message += '</ul>';

                        $('#reportingUserErrorMsg').html($.parseHTML(message));
                        return;
                    }
                    $('#reportUserDialog').hide();
                    $('#resetDiag').show();
                },
                failure: function (xhr, status, err) {
                    console.error(status, err.toString());
                },
                complete: function () {
                    DmUiLibrary.HideOverlaySpinner();
                }
            });

        }
    };

    return {
        init: function () {
            private.setupOnClickEvent();
        },
        errors: private.errors
    };

}();